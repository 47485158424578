html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
body {
  line-height: 1;
  color: #222;
  background: #fff;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
a img {
  border: none;
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url("fonts/Montserrat-Regular.eot?#iefix") format('embedded-opentype'), url("fonts/Montserrat-Regular.woff") format('woff'), url("fonts/Montserrat-Regular.ttf") format('truetype'), url("fonts/Montserrat-Regular.eot#Montserrat-Regular") format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url("fonts/Roboto-Medium.eot?#iefix") format('embedded-opentype'), url("fonts/Roboto-Medium.woff") format('woff'), url("fonts/Roboto-Medium.ttf") format('truetype'), url("fonts/Roboto-Medium.eot#Roboto-Medium") format('svg');
  font-weight: normal;
  font-style: normal;
}
body {
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
}
::-moz-selection {
  background: #747474;
  color: #89ff81;
}
::selection {
  background: #747474;
  color: #89ff81;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
h1,
h1(),
h2,
h2(),
h3,
h3(),
h4,
h4(),
h5,
h5(),
h6,
h6(),
ol,
ol(),
strong,
b,
bold(),
hr,
rule($color: #476594),
a,
link($style: 'glow'),
blockquote,
blockquote($color: #476594),
p,
padding-top 1px,
hyphenation(),
text-margin(),
paragraph: 18px false,
img {
  width: 100%;
  object-fit: contain;
}
#growPoolOuterContainer {
  font-family: Montserrat-Regular;
  text-align: center;
  margin: 5%;
  padding: 3px;
  background-color: #000;
}
#fourOhFour {
  margin: 20%;
  background: #f8e057;
  background: #eee linear-gradient(180deg, #f8e057, #eee) repeat-x;
  background: #e88074;
  background: #e67467 linear-gradient(180deg, #e88074, #e67467) repeat-x;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #e26153;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.05);
  position: relative;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
#fourOhFour:after {
  content: "";
  display: block;
  clear: both;
}
.fadeInAnimated {
  animation: fadeInOutAnimation 1.5s ease-in-out;
  animation-direction: normal;
}
.fadeInAnimatedFast {
  animation: fadeInOutAnimation 0.2s ease-in;
  animation-direction: normal;
}
.growPoolHeaderContainer {
  margin: 8% 10% 8% 10%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
  justify-content: center;
}
.growPoolHeaderContainer .growPoolHeaderFlowElement {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}
.growPoolHeaderContainer .growPoolHeaderFlowElement:active {
  box-shadow: inset 0px 10px 20px rgba(0,0,0,0.35);
}
@media screen and (min-width: 45.714285714285715rem) {
  .growPoolHeaderContainer .growPoolHeaderFlowElement {
    flex-basis: calc(100% * 0.5);
    min-width: 0;
  }
}
@media screen and (min-width: 71.42857142857143rem) {
  .growPoolHeaderContainer .growPoolHeaderFlowElement {
    flex-basis: calc(100% * 0.5);
    min-width: 0;
  }
}
.growPoolContentContainer {
  min-height: 400px;
  margin-left: 5%;
  margin-right: 5%;
}
@media screen and (min-width: 71.42857142857143rem) {
  .growPoolContentContainer {
    margin: 200px;
  }
}
.hidden {
  opacity: 0;
}
.showing {
  opacity: 100;
}
.growPoolButtonDisplay {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
  justify-content: center;
}
.growPoolButtonDisplay .growPoolFlowButton {
  height: 120px;
}
.growPoolButtonDisplay .growPoolFlowButton:hover {
  position: relative;
  z-index: 10;
  transform: scale(1.2);
}
.growPoolButtonDisplay .growPoolFlowButton:hover {
  box-shadow: 0px 0px 20px 2px #fff;
}
.growPoolButtonDisplay .growPoolFlowButton img {
  max-height: 120px;
}
@media screen and (min-width: 0rem) {
  .growPoolButtonDisplay .growPoolFlowButton {
    flex-basis: calc(100% * 1);
    min-width: 0;
  }
}
@media screen and (min-width: 32.142857142857146rem) {
  .growPoolButtonDisplay .growPoolFlowButton {
    flex-basis: calc(100% * 0.5);
    min-width: 0;
  }
}
@media screen and (min-width: 71.42857142857143rem) {
  .growPoolButtonDisplay .growPoolFlowButton {
    flex-basis: calc(100% * 0.25);
    min-width: 0;
  }
}
.growPoolFooterContainer {
  font-family: Roboto-Medium;
  color: #1d8d00;
  padding: 2%;
}
.growPoolFooterContainer b {
  color: #06c508;
}
.growPoolFooterContainer a {
  color: #0074d9;
  text-decoration: none;
  transition: all .3s ease;
}
.growPoolFooterContainer a:hover {
  color: #068bff;
}
.growPoolFooterContainer a:visited {
  opacity: 0.8;
}
#growPoolNewsContentContainer {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
.newsArticleFlowElement {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.05);
  background-color: #016c9d;
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
  min-height: 150px;
  margin-top: 20px;
}
.newsArticleFlowElement .newsAuthor {
  font-size: 36px;
  font-size: 2.4rem;
  text-rendering: optimizelegibility;
  font-weight: bold;
  margin: 0.75em 0;
  line-height: 1.6em;
  font-size: 16px;
}
.newsArticleFlowElement .newsArticle {
  font-size: 14px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.05);
  background-color: #00f8f8;
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
}
.newsArticleFlowElement .newsArticle .newsPublishedDate {
  font-size: 12px;
  background: "#DDD";
  background: "444" linear-gradient(180deg, "#DDD", "444") repeat-x;
}
#requestLinkPageContainer {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.05);
  background-color: #3ea4ee;
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
  color: #eee;
}
.growPoolLabel {
  padding-right: 10px;
  line-height: 1.5em;
  display: inline-block;
  font-weight: bold;
}
.growPoolInput {
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: solid 1px #bbb;
  border-radius: 0.2em;
  outline: none;
  padding: 0.45em;
  background: #fff;
  color: #555;
  width: 250px;
  text-shadow: 0 0 1px rgba(255,255,255,0.1);
  transition: all .3s ease;
}
.growPoolInput:focus {
  box-shadow: 0 0 5px rgba(127,219,255,0.7);
  border: 1px solid #95d1e9;
  outline: none;
}
.growPoolFormError {
  background: #e88074;
  background: #e67467 linear-gradient(180deg, #e88074, #e67467) repeat-x;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #e26153;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.05);
  position: relative;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
  color: #930000;
}
.growPoolFormError:after {
  content: "";
  display: block;
  clear: both;
}
.growPoolSubmitButton {
  margin-top: 10px;
  font-size: 13px;
  padding: 10px 22px;
  border-radius: 3px;
  background-color: #29ff00;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none;
  -webkit-touch-callout: none;
  user-select: none;
}
.growPoolSubmitButton:hover,
.growPoolSubmitButton:focus {
  background-color: #27f200;
  color: #f7f7f7;
  border: none;
}
.growPoolSubmitButton:active {
  background-color: #25e600;
}
.error {
  color: #d13f19;
}
.error input {
  color: #d13f19;
  border-color: #d13f19;
}
.error input:focus {
  box-shadow: 0 0 5px rgba(209,63,25,0.7);
  border: 1px solid #b15239;
  outline: none;
}
@-moz-keyframes fadeInOutAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
@-webkit-keyframes fadeInOutAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
@-o-keyframes fadeInOutAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
@keyframes fadeInOutAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
